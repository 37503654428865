<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="newspaper.releaseDate"
                        label="Choose newspaper release date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        :readonly="readOnly"
                        clearable
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="newspaper.releaseDate"
                    :model-config="modelConfig"
                    class="datepicker"
                    :readonly="readOnly"
                    mode="dateTime"
                />
            </v-menu>
            <v-file-input
                v-if="mode !== 'show'"
                v-model="newImages"
                label="Image input"
                filled
                accept="image/jpg, image/jpeg, image/png"
                multiple
                :rules="fileRules"
                show-size
                prepend-icon="mdi-camera"
                @change="onImageChange"
            />
            <div class="image-input-wrapper">
                <div v-for="(previewImage, index) of previewImages" :key="index" class="image-input" :class="{'green-border': !previewImage.id}">
                    <div v-if="['edit', 'create'].includes(mode)" class="delete-btn">
                        <v-btn class="mx-2 white--text" small color="red" @click="onImageDelete(previewImage)">
                            X
                        </v-btn>
                    </div>
                    <v-img v-if="previewImage.url" class="img-preview" :src="previewImage.url" />
                </div>
            </div>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" :disabled="shouldDisableSubmit()" large color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import imageService from '@/services/image-service'
    import newspaperService from '@/services/newspaper-service'
    import moment from 'moment'

    export default {
        name: 'NewspaperForm',
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN,
                available: null,
                newspaper: {
                    releaseDate: null
                },
                newspaperId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                fileRules: [
                    files => !files || !files.some(file => file.size > 2000000) || 'Image size should be less than 2 MB!'
                ],
                modelConfig: {
                    type: 'String',
                    mask: 'yyyy-MM-DD'
                },
                newImages: [],
                previewImages: []
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'create') {
                return
            } else if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.newspaperId = this.$route.params.newspaperId
            if (this.newspaperId) {
                newspaperService.getNewspaperById(this.newspaperId).then(resp => {
                    this.newspaper = resp.data.newspaper
                    this.previewImages = [ ...this.newspaper.pages ]
                    this.prepareImageUrls()
                    this.newspaper.releaseDate = this.formatDate(this.newspaper.releaseDate, 'yyyy-MM-DD')
                })
            }
        },
        methods: {
            onImageChange(images) {
                if (images.length === 0) {
                    this.previewImages = [ ...this.newspaper.pages ]
                    return
                }
                for (const newImage of this.newImages) {
                    newImage.url = null
                    newImage.url = URL.createObjectURL(newImage)
                    this.previewImages.push(newImage)
                }
            },
            onImageDelete(previewImage) {
                if (previewImage.id) {
                    const newspaperImage = this.newspaper.pages.find(image => { return image.id === previewImage.id })
                    newspaperImage.shouldDelete = true
                }
                this.previewImages.splice(this.previewImages.indexOf(previewImage), 1)
                this.newImages.splice(this.newImages.indexOf(previewImage), 1)
            },
            shouldDisableSubmit() {
                return !this.newspaper.releaseDate || !(this.newImages.length > 0) || !this.newImages.some(image => image.size < 2000000)
            },
            async createNewspaper() {
                const response = await newspaperService.createNewspaper(this.newspaper)
                if (response.data.id) {
                    this.newspaperId = response.data.id
                    this.uploadImages()
                }
                this.$router.push(`/newspaper`)
            },
            async editNewspaper() {
                await newspaperService.editNewspaperById(this.newspaperId, this.newspaper)
                this.uploadImages()
                this.$router.push(`/newspaper`)
            },
            async onSubmit() {
                if (this.mode === 'edit') {
                    this.editNewspaper()
                } else if (this.mode === 'create') {
                    this.createNewspaper()
                }
                this.deleteNewspaperImages()
            },
            deleteNewspaperImage() {
                if (this.previewImage && this.previewImage.id && this.previewImage.shouldDelete) {
                    imageService.deleteImageById(this.previewImage.id)
                    this.previewImage = null
                }
            },
            onEdit() {
                this.$router.push(`/newspaper/${this.newspaperId}/edit`)
            },
            createNewImagePreview() {
                this.previewImage = this.newImage
                this.previewImage.url = URL.createObjectURL(this.newImage)
                this.previewImage.description = null
            },
            deleteNewspaperImages() {
                if (!this.newspaper.pages) {
                    return
                }
                for (const image of this.newspaper.pages) {
                    if (image.shouldDelete) {
                        imageService.deleteImageById(image.id)
                    }
                }
            },
            uploadImages() {
                if (!this.newImages.length > 0) {
                    return
                }
                for (const image of this.newImages) {
                    let formData = new FormData()
                    formData.append('file', image)
                    formData.append('newspaperId', this.newspaperId)
                    imageService.uploadNewNewspaperImage(formData)
                }
            },
            prepareImageUrls() {
                for (const image of this.previewImages) {
                    image.url = this.apiUrl + '/uploads/images/' + image.imageName
                }
                console.log(this.previewImages)
            },
            formatDate(date, format) {
                if (!date) {
                    return null
                }
                return moment(date).format(format)
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
/* .tox .tox-statusbar {
    display: none !important;
} */

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
